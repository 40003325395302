import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import ActionButtons from "../../../components/action_buttons";

const CountriesForm = ({ onHide, formData, onSave }) => {
  const purposeOptions = [
    { label: "Personal Family Trip", value: "Personal Family Trip" },
  ];

  const typeOptions = [{ label: "Private Trip", value: "Private Trip" }];

  const handleSave = () => {
    onSave();
  };

  return (
    <div className="main-form">
      <form>
        <div className="p-fluid formgrid grid">
          <div className="field md:col-4">
            <label htmlFor="name">Name</label>
            <InputText id="name" placeholder="Enter" />
          </div>

          <div className="field md:col-4">
            <label htmlFor="country">Country</label>
            <InputText id="country" placeholder="Enter" />
          </div>

          <div className="field md:col-4">
            <label htmlFor="type">Trip Type</label>
            <Dropdown
              id="type"
              options={typeOptions}
              placeholder="Select type"
            />
          </div>
          <div className="field md:col-4">
            <label htmlFor="purpose">Purpose</label>
            <Dropdown
              id="purpose"
              options={purposeOptions}
              placeholder="Select Purpose"
            />
          </div>

          <div className="field md:col-4">
            <label htmlFor="startingDate">Starting Date</label>
            <Calendar id="startingDate" placeholder="Enter" showIcon />
          </div>
          <div className="field md:col-4">
            <label htmlFor="endingDate">Ending Date</label>
            <Calendar id="endingDate" placeholder="Enter" showIcon />
          </div>
        </div>

        <div className="p mt-4 form-buttons">
          <ActionButtons
            onCancel={onHide}
            onSave={handleSave}
            saveLabel="Save Changes"
            showSave={true}
            cancelLabel="Cancel"
          />{" "}
        </div>
      </form>
    </div>
  );
};

export default CountriesForm;
