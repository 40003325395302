import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import config from "../../../config";
import { toast } from "react-toastify";

// Initial state
const initialState = {
  sportsComplexList: [],
  citiesList: [],
  complexWithCitiesList: [],
  sportComplexByIdList:[],
  loading: false,
  error: null,
};

// Slice definition
const sportsComplexReducer = createSlice({
  name: "sportsComplex",
  initialState,
  reducers: {
    resetChangeStatus(state, action) {
      state.addSuccess = undefined;
      state.updateSuccess = undefined;
      state.addSuccess = undefined;
      state.deleteSuccess = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSportsComplexList.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSportsComplexList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.sportsComplexList = action.payload.sports;
      })
      .addCase(getSportsComplexList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getSportComplexById.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSportComplexById.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.sportComplexByIdList = action.payload.data;
      })
      .addCase(getSportComplexById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getCitiesList.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCitiesList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.citiesList = action.payload.cities;
      })
      .addCase(getCitiesList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createSportsComplex.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createSportsComplex.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.addSuccess = action.payload;
      })
      .addCase(createSportsComplex.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        toast.error("Failed to add sport complex");
      })
      .addCase(getSportsComplexWithCities.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSportsComplexWithCities.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.complexWithCitiesList = action.payload.complexList;
      })
      .addCase(getSportsComplexWithCities.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Thunks
export const getSportsComplexList = createAsyncThunk(
  "sportsComplex/fetch",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Axios.post(
        `${config.baseUrl}${config.tellerAppPath}/GetAllSports`
      );
      return response.data;
    } catch (error) {
      throw rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);

export const getSportsComplexWithCities = createAsyncThunk(
  "sportsComplexWithCities/fetch",
  async (mobileNumber, { rejectWithValue }) => {
    try {
      const requestData = {
        mobileNumber: mobileNumber,
      };

      const response = await Axios.post(
        `${config.baseUrl}${config.tellerAppPath}/GetComplexWithCities`,
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);


export const createSportsComplex = createAsyncThunk(
  "sportsComplex/create",
  async (sportsComplexData, { rejectWithValue }) => {
    try {
      const response = await Axios.post(
        `${config.baseUrl}${config.tellerAppPath}/AddSportsComplex`,
        sportsComplexData
      );
      return response.data;
    } catch (error) {
      throw rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);

// Export reducer and actions
export default sportsComplexReducer.reducer;
export const { resetChangeStatus } = sportsComplexReducer.actions;

// Thunk for fetching cities
export const getCitiesList = createAsyncThunk(
  "cities/fetch",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Axios.post(
        `${config.baseUrl}${config.tellerAppPath}/GetAllCities`
      );
      return response.data;
    } catch (error) {
      throw rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);

export const getSportComplexById = createAsyncThunk(
  "sportsById/fetch",
  async (id, { rejectWithValue }) => {
    try {
      const response = await Axios.post(
        `${config.baseUrl}${config.tellerAppPath}/GetSportsComplexById/${id}`,
      
      );
      return response.data;
    } catch (error) {
      throw rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);
