import React, { useState, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import LoginScreen from "./app/features/screens/auth/loginscreen";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "react-toastify/dist/ReactToastify.css";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./assets/demo/flags/flags.css";
import "./assets/demo/Demos.scss";
import "./assets/layout/layout.scss";
import "./styles/app.scss";
import "./styles/form.scss";
import "./styles/login.scss";
import "./styles/dashboard.scss";
import DashboardLayout from "./layoutes/layout";
import DashboardScreen from "./app/features/screens/dashboard/dashboard";
import EmployeeScreen from "./app/features/screens/employee/employee";
import AttendanceScreen from "./app/features/screens/attendance/attendance";
import LeaveScreen from "./app/features/screens/leave/leave";
import ShiftScreen from "./app/features/screens/shift/shift";
import SettingsScreen from "./app/features/screens/settings/settings";
import EmployeeDetails from "./app/features/screens/employee/details/details";
import LeaveDetails from "./app/features/screens/leave/details";


const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const storedLoginStatus = localStorage.getItem("isLoggedIn");
    if (storedLoginStatus === "true") {
      setIsLoggedIn(true);
    }
  }, []);

  const handleLogin = () => {
    setIsLoggedIn(true);
    localStorage.setItem("isLoggedIn", "true");
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("isLoggedIn");
  };

  return (
    <>
      <ToastContainer />
      <Routes>
        <Route
          path="/login"
          element={<LoginScreen handleLogin={handleLogin} />}
        />
        {!isLoggedIn ? (
          <>
            <Route
              path="/"
              element={
                <DashboardLayout
                  isLoggedIn={isLoggedIn}
                  handleLogout={handleLogout}
                />
              }
            >
              <Route path="/" element={<DashboardScreen />} />
              <Route path="/employee" element={<EmployeeScreen />} />
              <Route
                path="/employee/details/:empId"
                element={<EmployeeDetails />}
              />
              <Route path="/attendance" element={<AttendanceScreen />} />
              <Route path="/leave" element={<LeaveScreen />} />
              <Route path="/leave/details/:empId" element={<LeaveDetails />} />
              <Route path="/shift" element={<ShiftScreen />} />
              <Route path="/settings" element={<SettingsScreen />} />
            </Route>
          </>
        ) : (
          <>
            <Route path="/" element={<Navigate to="/login" />} />
          </>
        )}
      </Routes>
    </>
  );
};

export default App;
