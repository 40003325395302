import React from "react";
import { Button } from "primereact/button";

const ActionButtons = ({ onCancel, onSave, loading, saveLabel, showSave, cancelLabel }) => {
  return (
    <div className="flex button-resp">
      {onCancel && (
        <Button
          label={cancelLabel}
          onClick={onCancel}
          className="p-button-outlined mr-2"
          type="button"
          style={{ flex: "1" }}
        />
      )}
      {showSave && onSave && (
        <Button
          loading={loading}
          label={saveLabel}
          onClick={onSave}
          className="p-button"
          type="submit"
          style={{ flex: "1" }}
        />
      )}
    </div>
  );
};

export default ActionButtons;
