import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import ActionButtons from "../../../components/action_buttons";

const ServicesForm = ({ onHide, formData, onSave }) => {
  const yearOptions = [
    { label: "2005", value: "2005" },
    { label: "2006", value: "2006" },
    { label: "2007", value: "2007" },
    { label: "2008", value: "2008" },
  ];

  const handleSave = () => {
    onSave();
  };

  return (
    <div className="main-form">
      <form>
        <div className="p-fluid formgrid grid">
          <div className="field md:col-4">
            <label htmlFor="name">Name</label>
            <InputText id="name" placeholder="Enter" />
          </div>

          <div className="field md:col-4">
            <label htmlFor="year">Service Yaer</label>
            <Dropdown
              id="type"
              options={yearOptions}
              placeholder="Select year"
            />{" "}
          </div>

          <div className="field md:col-4">
            <label htmlFor="grade">Post Held & Grade</label>
            <InputText id="grade" placeholder="Enter" />
          </div>

          <div className="field md:col-4">
            <label htmlFor="department">Department</label>
            <InputText id="department" placeholder="Enter" />
          </div>

          <div className="field md:col-4">
            <label htmlFor="startingDate">Post Held From​</label>
            <Calendar id="startingDate" placeholder="Enter" showIcon />
          </div>
          <div className="field md:col-4">
            <label htmlFor="endingDate">Post Held To</label>
            <Calendar id="endingDate" placeholder="Enter" showIcon />
          </div>
          <div className="field md:col-4">
            <label htmlFor="station">Station</label>
            <InputText id="station" placeholder="Enter" />
          </div>
        </div>

        <div className="p mt-4 form-buttons">
          <ActionButtons
            onCancel={onHide}
            onSave={handleSave}
            saveLabel="Save Changes"
            showSave={true}
            cancelLabel="Cancel"
          />{" "}
        </div>
      </form>
    </div>
  );
};

export default ServicesForm;
