import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import ActionButtons from "../../components/action_buttons";
import { InputTextarea } from "primereact/inputtextarea";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { MultiSelect } from "primereact/multiselect";

const EditAddForm = ({ onHide, formData }) => {
  const navigate = useNavigate();

  const [showDialog, setShowDialog] = useState(false);
  const [value, setValue] = useState("");
  const [fileName, setFileName] = useState("");
  const [selectedShiftDays, setSelectedShiftDays] = useState([]);

  const groupedShiftDays = [
    {
      label: "Weekdays",
      items: [
        { label: "Monday", value: "Monday" },
        { label: "Tuesday", value: "Tuesday" },
        { label: "Wednesday", value: "Wednesday" },
        { label: "Thursday", value: "Thursday" },
        { label: "Friday", value: "Friday" },
      ],
    },
    {
      label: "Weekends",
      items: [
        { label: "Saturday", value: "Saturday" },
        { label: "Sunday", value: "Sunday" },
      ],
    },
  ];

  const leaveOptions = [
    { label: "Medical Leave", value: "Medical Leave" },
    { label: "Casual Leave", value: "Casual Leave" },
  ];

  const shiftOptions = [
    { label: "Morning", value: "Morning" },
    { label: "Evening", value: "Evening" },
    { label: "Night", value: "Night" },
  ];

  return (
    <>
      <div className="main-form">
        <form>
          <div className="p-fluid formgrid grid">
            <div className="field md:col-3">
              <label htmlFor="shift">Shift Name</label>
              <Dropdown
                id="shift"
                options={shiftOptions}
                placeholder="Select a Shift"
              />
            </div>
            <div className="field md:col-3">
              <label htmlFor="shiftIncharge">Shift Incharge</label>
              <InputText id="shiftIncharge" placeholder="Enter" />
            </div>
            <div className="field md:col-3">
              <label htmlFor="shiftDays">Shift Days</label>
              <MultiSelect
                value={selectedShiftDays}
                options={groupedShiftDays}
                onChange={(e) => setSelectedShiftDays(e.value)}
                optionLabel="label"
                optionGroupLabel="label"
                optionGroupChildren="items"
                placeholder="Select Shift Days"
                display="chip"
              />
            </div>

            <div className="field md:col-3">
              <label htmlFor="startTime">Starting Time</label>
              <Calendar
                id="startTime"
                placeholder="Enter"
                showIcon
                hourFormat="12"
                timeOnly
              />
            </div>

            <div className="field md:col-3">
              <label htmlFor="endTime">Ending Time</label>
              <Calendar
                id="endTime"
                placeholder="Enter"
                showIcon
                hourFormat="12"
                timeOnly
              />
            </div>

            <div className="field md:col-3">
              <label htmlFor="workingHours">Working Hours ​</label>
              <InputText id="workingHours" placeholder="Enter" />
            </div>
          </div>
          <div className="p mt-4 form-buttons">
            <ActionButtons
              onCancel={onHide}
              onSave={onHide}
              saveLabel="Save Changes"
              showSave={true}
              cancelLabel="Cancel"
            />{" "}
          </div>
        </form>
      </div>
    </>
  );
};

export default EditAddForm;
