import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import ActionButtons from "../../../components/action_buttons";
import { InputTextarea } from "primereact/inputtextarea";

const TrainingForm = ({ onHide, formData, onSave }) => {
  const [value, setValue] = useState("");

  const typeOptions = [
    { label: "In Service Training​", value: "In Service Training​" },
  ];

  const cityOptions = [{ label: "Islamabad​", value: "Islamabad​​" }];

  
  const handleSave = () => {
    onSave();
  };


  return (
    <div className="main-form">
      <form>
        <div className="p-fluid formgrid grid">
          <div className="field md:col-4">
            <label htmlFor="name">Name</label>
            <InputText id="name" placeholder="Enter" />
          </div>

          <div className="field md:col-4">
            <label htmlFor="type">Training Type</label>
            <Dropdown
              id="type"
              options={typeOptions}
              placeholder="Select type"
            />{" "}
          </div>

          <div className="field md:col-4">
            <label htmlFor="startingDate">Starting Date</label>
            <Calendar id="startingDate" placeholder="Enter" showIcon />
          </div>

          <div className="field md:col-4">
            <label htmlFor="endingDate">Ending Date</label>
            <Calendar id="endingDate" placeholder="Enter" showIcon />
          </div>

          <div className="field md:col-4">
            <label htmlFor="city">City</label>
            <Dropdown
              id="city"
              options={cityOptions}
              placeholder="Select City"
            />{" "}
          </div>

          <div className="field md:col-4">
            <label htmlFor="country">Country</label>
            <InputText id="country" placeholder="Enter" />
          </div>

          <div className="field md:col-12">
            <label htmlFor="institute">Institution</label>
            <InputTextarea
              value={value}
              onChange={(e) => setValue(e.target.value)}
              rows={4}
              cols={10}
            />
          </div>
        </div>

        <div className="p mt-4 form-buttons">
          <ActionButtons
            onCancel={onHide}
            onSave={handleSave}
            saveLabel="Save Changes"
            showSave={true}
            cancelLabel="Cancel"
          />{" "}
        </div>
      </form>
    </div>
  );
};

export default TrainingForm;
