import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import { Dialog } from "primereact/dialog";
import logo from "../../../../assets/assests/logo.png";
import google from "../../../../assets/assests/google.png";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import config from "../../../../config";
import OtpScreen from "./otpscreen";

export default function LoginScreen() {
  const { loading } = useSelector((state) => state.loginUser);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const [userName, setUserName] = useState("");
  const formik = useFormik({
    initialValues: {
      userName: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required("Password is required."),
      userName: Yup.string()
        .required("User Name is required.")
      // .max(11, " Allow 11 characters"),
    }),
    onSubmit: async (values) => {
      try {
        const response = await axios.post(
          `${config.baseUrl}api/Account/login`,
          values,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data?.data?.otp) {
          localStorage.setItem('authToken', response.data.data.accessToken);
          setUserName(values.userName);
          setShowDialog(true);
          toast.success("OTP sent to your number. Please enter it.");
        } else {
          toast.error(response.data.message || "Login failed");
        }
      } catch (error) {
        console.error("Login error:", error);
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Login failed due to a network error. Please try again.");
        }
      }
    },
  });

  const handleRedirect = () => {
    navigate("/register");
  };
  const onHide = () => {
    setShowDialog(false);
  };

  return (
    <>
      <Dialog
       header={
          <div className="popup-header">
            <div className="popup-header-text">
          OTP
            </div>
          </div>
        }
        style={{ width: '30vw',height:'300px' }}
         visible={showDialog} onHide={onHide}
           className="dialog-size"
      >
        <OtpScreen userName={userName} onHide={onHide} />
      </Dialog>
      <div className="auth-page">
        <div class="container">
          <div class="content-wrapper">
            <div class="sign-in-box">
              <h2>Sign in</h2>
              <p class="create-account">
                New user? <a href="#">Create an account</a>
              </p>
              <form onSubmit={formik.handleSubmit}>
                <div class="input-group">
                  <FloatLabel>
                    <InputText
                      id="email"
                      name="userName"
                      value={formik.values.userName}
                      onChange={formik.handleChange}
                    />
                    <label htmlFor="email">User Name</label>
                  </FloatLabel>
                  {formik.touched.userName && formik.errors.userName ? (
                    <div className="error">{formik.errors.userName}</div>
                  ) : null}
                </div>
                <div class="input-group">
                  <FloatLabel>
                    <Password
                      feedback={false}
                      inputId="password"
                      id="password"
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      toggleMask />
                    <label htmlFor="password">Password</label>
                  </FloatLabel>
                  {formik.touched.password && formik.errors.password ? (
                    <div className="error">{formik.errors.password}</div>
                  ) : null}
                </div>
                <div class="links">
                  <a href="#">Can't sign in?</a>
                  <button type="submit" class="sign-in-button">
                    Sign In
                  </button>
                </div>

                <div class="divider">
                  <span></span>
                  <p>or</p>
                  <span></span>
                </div>
                <button type="button" class="google-sign-in">
                  <img src={google} alt="Google icon" /> Google
                </button>
                <p class="terms">
                  Protected by reCAPTCHA and subject to the{" "}
                  <a href="#">ET & NC Policy</a> and{" "}
                  <a href="#">Terms of Service</a>.
                </p>
              </form>
            </div>
            <div class="info-box">
              <img src={logo} alt="Zindigi logo" class="logo" />
              <h1>Optimize Workforce Management with Js Zindigi</h1>
              <p>
                Designed to streamline employee management, these tools offer
                seamless integration for tracking attendance, managing leaves,
                and analyzing HR data. Our platform ensures effortless
                administrative tasks and improved workforce productivity, all
                within a flexible and scalable cloud-based environment.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
