import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { useNavigate } from "react-router-dom";
import editIcon from "../../../../assets/assests/edit.png";
import eyeIcon from "../../../../assets/assests/eye.png";
import deleteIcon from "../../../../assets/assests/delete.png";
import { Dialog } from "primereact/dialog";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import EditAddForm from "./edit-add-form";

const shiftsData = [
  {
    shiftId: "ET-001",
    shiftName: "Morning",
    shiftUncharge: "Amad Khan",
    startingTime: "9:30 AM",
    endingTime: "6:30 PM",
    workingHours: "08 hrs",
    shiftDays: "Monday, Tuesday, Wednesday",
  },
  {
    shiftId: "ET-002",
    shiftName: "Evening",
    shiftUncharge: "Nimra Khan",
    startingTime: "6:30 PM",
    endingTime: "3:30 AM",
    workingHours: "10 hrs",
    shiftDays: "Monday, Tuesday",
  },
  {
    shiftId: "ET-003",
    shiftName: "Morning",
    shiftUncharge: "Basit Ali",
    startingTime: "9:30 AM",
    endingTime: "6:30 PM",
    workingHours: "08 hrs",
    shiftDays: "Wednesday, Thursday, Friday",
  },
];

export default function ShiftScreen() {
  const navigate = useNavigate();

  const [globalFilter, setGlobalFilter] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [showDialog, setShowDialog] = useState(false);

  const handleGlobalFilterChange = (e) => {
    setGlobalFilter(e.target.value);
  };

  const onHide = () => {
    setShowDialog(false);
  };

  const toggleDialogMode = (editMode = false) => {
    setShowDialog(true);
    setIsEdit(editMode);
  };

  const actionTemplate = (rowData) => {
    return (
      <div className="action-buttons flex">
        <Button
          className=" p-button-edit p-0 mr-3"
          onClick={() => {
            toggleDialogMode(true);
            setSelectedData(rowData);
          }}
          aria-label="Edit"
        >
          <img src={editIcon} alt="Edit" />
        </Button>

        <Button
          className="p-button-rounded p-button-delete p-0"
          aria-label="Delete"
        >
          <img src={deleteIcon} alt="Delete" />
        </Button>
      </div>
    );
  };

  const columnsData = [
    {
      field: "shiftId",
      header: "Shift ID",
    },
    {
      field: "shiftName",
      header: "Shift Name",
    },
    {
      field: "shiftUncharge",
      header: "Shift Uncharge",
    },
    {
      field: "startingTime",
      header: "Starting Time",
    },
    {
      field: "endingTime",
      header: "Ending Time",
    },
    {
      field: "workingHours",
      header: "Working Hours",
    },
    {
      field: "shiftDays",
      header: "Shift Days",
    },
    {
      header: "Action",
      body: actionTemplate,
    },
  ];

  return (
    <>
      <Dialog
        header={
          <div className="popup-header">
            <div className="popup-header-text">
              {isEdit ? "Edit Shift" : "Add Shift"}
            </div>
          </div>
        }
        visible={showDialog}
        onHide={onHide}
        className="dialog-size"
      >
        <EditAddForm onHide={onHide} formData={isEdit ? selectedData : null} />
      </Dialog>
      <div className="grid align-items-center mb-3">
        <div className="col-12 md:col-6">
          <h5 className="pages-internal-heading">Shift Management</h5>
        </div>

        <div className="col-12 md:col-6 justify-content-end filter-responsive">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="mbl_view"
              placeholder="Search"
              value={globalFilter}
              onChange={handleGlobalFilterChange}
            />
          </span>
          <Button
            label="Add New"
            icon="pi pi-plus"
            onClick={() => toggleDialogMode(false)}
            className="p-button ml-3"
          />
        </div>
      </div>
      <div className="card">
        <DataTable
          emptyMessage="No record found."
          value={shiftsData}
          paginator
          rows={10}
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          className="custom-data-table"
          globalFilter={globalFilter}
        >
          {columnsData.map((data, index) => (
            <Column
              key={index}
              field={data.field}
              header={data.header}
              body={data.body}
            />
          ))}
        </DataTable>
      </div>
    </>
  );
}
