import React, { useEffect, useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { TabView, TabPanel } from "primereact/tabview";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { Menu } from "primereact/menu";
import { Calendar } from "primereact/calendar";
import config from "../../../../config";
const departmentAttendance = [
  {
    depId: "ET-001",
    department: "ET & NC",
    total: 75,
    present: 50,
    absent: 10,
    leave: 5,
    lateArrival: 10,
    status: true,
  },
  {
    depId: "ET-002",
    department: "Punjab Excise",
    total: 125,
    present: 100,
    absent: 18,
    leave: 12,
    lateArrival: 20,
    status: false,
  },
  {
    depId: "ET-003",
    department: "ET & NC",
    total: 75,
    present: 50,
    absent: 10,
    leave: 5,
    lateArrival: 10,
    status: true,
  },
];

const employeeAttendance = [
  {
    empId: "ET-001",
    name: "Amad Khan",
    cnic: "61101-6258356-9",
    currentPost: "General Manager",
    shift: "Morning",
    checkIn: "9:35 AM",
    checkOut: "6:30 PM",
    isApproved: true,
  },
  {
    empId: "ET-002",
    name: "Nimra Khan",
    cnic: "12201-3058326-5",
    currentPost: "Assistant",
    shift: "Evening",
    checkIn: "11:28 AM",
    checkOut: "6:20 PM",
    isApproved: false,
  },
  {
    empId: "ET-003",
    name: "Basit Ali",
    cnic: "61101-6258356-9",
    currentPost: "System Analyst",
    shift: "Morning",
    checkIn: "9:20 AM",
    checkOut: "6:10 PM",
    isApproved: true,
  },
];

export default function AttendanceScreen() {
  const menu = useRef(null);

  const [attendance, setAttendance] = useState(employeeAttendance);
  const [deptAttendance, setDeptAttendance] = useState(departmentAttendance);
  const [globalFilter, setGlobalFilter] = useState("");
  const [employeeAttendanceList, setEmployeeAttendanceList] = useState();
  const [epartmentAttendanceList, setDepartmentAttendanceList] = useState();

  const handleGlobalFilterChange = (e) => {
    setGlobalFilter(e.target.value);
  };

  const GetEmployeeAttendance = async () => {
    const token = localStorage.getItem('authToken')
    
    try {
      const response = await fetch(`${config.baseUrl}api/Attendance/GetEmployeeAttendanceDetail`,
        {
          method: 'Get',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      )
      const data =await response.json();
      setEmployeeAttendanceList(data);
    }
    catch (error) {

    }

  };
  const GetDepartmentAttendance = async () => {
    const token = localStorage.getItem('authToken')
    
    try {
      const response = await fetch(`${config.baseUrl}api/Attendance/GetDepartmentAttendanceDetail`,
        {
          method: 'Get',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      )
      const data =await response.json();
      setDepartmentAttendanceList(data);
    }
    catch (error) {

    }

  }



  useEffect(() => {
    GetEmployeeAttendance();
    GetDepartmentAttendance();
  }, [])

  const deptAttendanceColumns = [
    { field: "depId", header: "Dep ID" },
    { field: "department", header: "Department" },
    { field: "total", header: "Total" },
    { field: "present", header: "Present" },
    { field: "absent", header: "Absent" },
    { field: "leave", header: "Leave" },
    { field: "lateArrival", header: "Late Arrival" },
    {
      field: "status",
      header: "Status",
      body: (rowData) => {
        if (rowData.status === true) {
          return <span className="custom-tag tag-green">Published</span>;
        } else if (rowData.status === false) {
          return <span className="custom-tag tag-red">Low Stock</span>;
        } else {
          return null;
        }
      },
    },
  ];

  const attendanceColumns = [
    { field: "empId", header: "Emp ID" },
    { field: "name", header: "Name" },
    { field: "cnic", header: "CNIC" },
    { field: "currentPost", header: "Current Post" },
    { field: "shift", header: "Shift" },
    { field: "checkIn", header: "Check-In" },
    {
      field: "checkOut",
      header: "Check-Out",
      body: (rowData) => {
        if (rowData.isApproved === true) {
          return <span className="custom-tag tag-green">Present</span>;
        } else if (rowData.isApproved === false) {
          return <span className="custom-tag tag-red">Late</span>;
        } else {
          return null;
        }
      },
    },
  ];

  const [selectedVertical, setSelectedVertical] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedDesignation, setSelectedDesignation] = useState(null);

  const verticals = [
    { label: "Finance", value: "Finance" },
    { label: "HR", value: "HR" },
    { label: "Engineering", value: "Engineering" },
  ];

  const departments = [
    { label: "Accounts", value: "Accounts" },
    { label: "Recruitment", value: "Recruitment" },
    { label: "Software", value: "Software" },
  ];

  const designations = [
    { label: "Manager", value: "Manager" },
    { label: "Engineer", value: "Engineer" },
    { label: "Technician", value: "Technician" },
  ];

  const handleFilterApply = () => {
    console.log("Filters Applied", {
      selectedVertical,
      selectedDepartment,
      selectedDesignation,
    });
  };

  const menuItems = [
    {
      items: [
        {
          template: () => (
            <div className="p-field p-fluid filters-label">
              <p>Filters</p>
              <i
                className="pi pi-times"
                onClick={() => {
                  setSelectedVertical(null);
                  setSelectedDepartment(null);
                  setSelectedDesignation(null);
                }}
              ></i>
            </div>
          ),
        },
        {
          label: "Verticals",
          template: () => (
            <div className="p-field p-fluid mt-3">
              <Dropdown
                value={selectedVertical}
                options={verticals}
                onChange={(e) => setSelectedVertical(e.value)}
                placeholder="Select Verticals"
                appendTo="self"
              />
            </div>
          ),
        },
        {
          label: "Department",
          template: () => (
            <div className="p-field p-fluid mt-3">
              <Dropdown
                value={selectedDepartment}
                options={departments}
                onChange={(e) => setSelectedDepartment(e.value)}
                placeholder="Select Department"
                appendTo="self"
              />
            </div>
          ),
        },
        {
          label: "Designation",
          template: () => (
            <div className="p-field p-fluid mt-3">
              <Dropdown
                value={selectedDesignation}
                options={designations}
                onChange={(e) => setSelectedDesignation(e.value)}
                placeholder="Select Designation"
                appendTo="self"
              />
            </div>
          ),
        },
        {
          label: "Actions",
          template: () => (
            <div
              className="p-field p-fluid mt-4"
              style={{ display: "flex", gap: "10px" }}
            >
              <Button
                label="Cancel"
                className="p-button-secondary"
                onClick={() => {
                  setSelectedVertical(null);
                  setSelectedDepartment(null);
                  setSelectedDesignation(null);
                }}
              />
              <Button
                label="Apply Filter"
                className="p-button-primary"
                onClick={handleFilterApply}
              />
            </div>
          ),
        },
      ],
    },
  ];

  return (
    <>
      <div className="grid align-items-center mb-3">
        <div className="col-12 md:col-6">
          <h5 className="pages-internal-heading">Attendance Management</h5>
        </div>

        <div className="col-12 md:col-6 justify-content-end filter-responsive">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="mbl_view"
              placeholder="Search"
              value={globalFilter}
              onChange={handleGlobalFilterChange}
            />
          </span>
          <Calendar
            id="date"
            placeholder="Calender"
            showIcon
            className="ml-3 "
          />
          <Menu model={menuItems} popup ref={menu} className="filter-menu" />
          <Button
            label="Filters"
            icon="pi pi-sliders-h"
            className="p-button ml-3 filters-btn"
            onClick={(e) => menu.current.toggle(e)}
          />
        </div>
      </div>

      <div className="card">
        <TabView>
          <TabPanel header="Employee’s  Attendance">
            <DataTable
              emptyMessage="No record found."
              value={attendance}
              className="custom-data-table"
            >
              {attendanceColumns.map((data, index) => (
                <Column
                  key={index}
                  field={data.field}
                  header={data.header}
                  body={data.body}
                />
              ))}
            </DataTable>
          </TabPanel>
          <TabPanel header="Department Attendance">
            <DataTable
              emptyMessage="No record found."
              value={deptAttendance}
              className="custom-data-table"
            >
              {deptAttendanceColumns.map((data, index) => (
                <Column
                  key={index}
                  field={data.field}
                  header={data.header}
                  body={data.body}
                />
              ))}
            </DataTable>
          </TabPanel>
        </TabView>
      </div>
    </>
  );
}
