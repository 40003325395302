import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import ActionButtons from "../../components/action_buttons";
import { InputTextarea } from "primereact/inputtextarea";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import tick from "../../../../assets/assests/tick-success.png";

const EditAddForm = ({ onHide, formData }) => {
  const navigate = useNavigate();

  const [showDialog, setShowDialog] = useState(false);
  const [value, setValue] = useState("");
  const [fileName, setFileName] = useState("");

  const leaveOptions = [
    { label: "Medical Leave", value: "Medical Leave" },
    { label: "Casual Leave", value: "Casual Leave" },
  ];

  const handleFileChange = (e) => {
    setFileName(e.target.files[0]?.name || "");
  };

  const handleRedirect = () => {
    navigate("/leave");
  };

  const dialogFooter = (
    <div>
      <Button
        label="Okay, Great!"
        style={{ margin: "auto", display: "flex", marginBottom: "20px" }}
        icon="pi pi-check"
        onClick={handleRedirect}
      />
    </div>
  );

  return (
    <>
      <ConfirmDialog
        className="dialog-header-hidden"
        visible={showDialog}
        footer={dialogFooter}
        message={
          <>
            <br />
            <img src={tick} style={{ margin: "auto", display: "flex" }} />
            <br />
            <div
              style={{
                textAlign: "center",
                lineHeight: "1.5",
                fontWeight: "600",
              }}
            >
              Request of leave type (Medical) has been <br />
              successfully sent to HOD.
            </div>
            <br />
          </>
        }
      />
      <div className="main-form">
        <form>
          <div className="p-fluid formgrid grid">
            <div className="field md:col-3">
              <label htmlFor="name">Name</label>
              <InputText id="name" placeholder="Enter" />
            </div>
            <div className="field md:col-3">
              <label htmlFor="currentPost">Current Post</label>
              <InputText id="currentPost" placeholder="Enter" />
            </div>
            <div className="field md:col-3">
              <label htmlFor="leaveType">Leave Type</label>
              <Dropdown
                id="leaveType"
                options={leaveOptions}
                placeholder="Select option"
              />
            </div>
            <div className="field md:col-3">
              <label htmlFor="from">Leave Require From</label>
              <Calendar id="from" placeholder="Enter" showIcon />
            </div>
            <div className="field md:col-3">
              <label htmlFor="to">To</label>
              <Calendar id="to" placeholder="Enter" showIcon />
            </div>
            <div className="field md:col-3">
              <label htmlFor="days">No. of Days​</label>
              <InputText id="days" placeholder="Enter" />
            </div>
            <div className="field md:col-3">
              <label htmlFor="submission">Submission Date</label>
              <Calendar id="submission" placeholder="Enter" showIcon />
            </div>
            <div className="field md:col-3">
              <label htmlFor="employeeSignature">Attachment</label>
              <div className="file-container">
                <input
                  type="file"
                  onChange={handleFileChange}
                  className="none-input"
                  id="attachment"
                />
                <label htmlFor="attachment" className="attach-picture">
                  Choose file
                </label>
                <span className="file-name">
                  {fileName || "No file chosen"}
                </span>
              </div>
            </div>
            <div className="field md:col-12">
              <label htmlFor="purpose">Purpose/Reason</label>
              <InputTextarea
                value={value}
                onChange={(e) => setValue(e.target.value)}
                rows={4}
                cols={10}
              />
            </div>
          </div>
          <div className="p mt-4 form-buttons">
            <ActionButtons
              onCancel={onHide}
              onSave={onHide}
              saveLabel="Save Changes"
              showSave={true}
              cancelLabel="Cancel"
            />{" "}
          </div>
        </form>
      </div>
    </>
  );
};

export default EditAddForm;
