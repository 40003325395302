import React, { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import { useNavigate, useLocation } from "react-router-dom";
import editIcon from "../../../../../assets/assests/edit.png";
import eyeIcon from "../../../../../assets/assests/eye.png";
import { Dialog } from "primereact/dialog";
import { Menu } from "primereact/menu";
import { TabView, TabPanel } from "primereact/tabview";
import QualificationForm from "./qualification-form";
import CountriesForm from "./countries-form";
import ServicesForm from "./services-form";
import TrainingForm from "./training-form";
import {
  employeeColumns,
  qualificationColumns,
  countriesColumns,
  servicesColumns,
  trainingColumns,
  actionTemplate,
} from "./columns-data";

const dummyQualifications = [
  {
    empId: "E001",
    employeeName: "John Doe",
    cnic: "12345-6789012-3",
    academic: "Bachelor's",
    specialization: "Computer Science",
    university: "ABC University",
    country: "USA",
  },
  {
    empId: "E002",
    employeeName: "Jane Smith",
    cnic: "98765-4321098-7",
    academic: "Master's",
    specialization: "Data Science",
    university: "XYZ University",
    country: "Canada",
  },
];

export default function EmployeeDetails() {
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const location = useLocation();

  const employee = location.state?.employee;
  const employees = employee ? [employee] : [];

  const [globalFilter, setGlobalFilter] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);
  const [dialogTitle, setDialogTitle] = useState("");

  const [activeIndex, setActiveIndex] = useState(0);
  const [qualifications, setQualifications] = useState(dummyQualifications);
  const [countries, setCountries] = useState([]);
  const [services, setServices] = useState([]);
  const [training, setTraining] = useState([]);

  const handleGlobalFilterChange = (e) => {
    setGlobalFilter(e.target.value);
  };

  const handleBackClick = () => {
    navigate("/employee");
  };

  const onHide = () => {
    setShowDialog(false);
    setDialogContent(null);
  };

  const openModal = (actionType, rowData = null, dataType) => {
    const formMapping = {
      qualification: {
        component: QualificationForm,
        state: qualifications,
        setState: setQualifications,
        index: 1,
      },
      countries: {
        component: CountriesForm,
        state: countries,
        setState: setCountries,
        index: 2,
      },
      services: {
        component: ServicesForm,
        state: services,
        setState: setServices,
        index: 3,
      },
      training: {
        component: TrainingForm,
        state: training,
        setState: setTraining,
        index: 4,
      },
    };

    const {
      component: FormComponent,
      state,
      setState,
      index,
    } = formMapping[dataType];

    const handleSave = (newData) => {
      if (rowData && rowData.empId) {
        const updatedState = state.map((item) =>
          item.empId === rowData.empId ? newData : item
        );
        setState(updatedState);
      }
      onHide();
      setActiveIndex(index);
    };

    setDialogContent(
      <FormComponent
        {...{ [dataType]: rowData }}
        onHide={onHide}
        onSave={handleSave}
      />
    );
    setDialogTitle(`${actionType} ${dataType}`);
    setShowDialog(true);
  };

  const items = [
    {
      label: "Add Qualification",
      command: () => openModal("Add", null, "qualification"),
    },
    {
      label: "Add Countries",
      command: () => openModal("Add", null, "countries"),
    },
    {
      label: "Add Services",
      command: () => openModal("Add", null, "services"),
    },
    {
      label: "Add Training",
      command: () => openModal("Add", null, "training"),
    },
  ];

  const renderDialogContent = () => dialogContent;

  return (
    <>
      <Dialog
        header={
          <div className="popup-header">
            <div className="popup-header-text">{dialogTitle}</div>
          </div>
        }
        visible={showDialog}
        onHide={onHide}
        className="dialog-size"
      >
        {renderDialogContent()}
      </Dialog>

      <div className="grid align-items-center mb-3">
        <div className="col-12 md:col-6">
          <h5 className="pages-internal-heading">
            <span onClick={handleBackClick} style={{ cursor: "pointer" }}>
              Employee Management
            </span>{" "}
            &gt; Employee Details
          </h5>
        </div>

        <div className="col-12 md:col-6 justify-content-end filter-responsive">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="mbl_view"
              placeholder="Search"
              value={globalFilter}
              onChange={handleGlobalFilterChange}
            />
          </span>
          <Menu model={items} popup ref={menuRef} />

          <Button
            className="p-button ml-3 add-new-dropdown"
            onClick={(event) => menuRef.current.toggle(event)}
          >
            <i className="pi pi-plus" />
            Add New
            <i className="pi pi-chevron-down" />
          </Button>
        </div>
      </div>

      <div className="card">
        <TabView
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
        >
          <TabPanel header="Employee Details">
            <DataTable
              emptyMessage="No record found."
              value={employees}
              className="custom-data-table"
              globalFilter={globalFilter}
            >
              {employeeColumns.map((data, index) => (
                <Column
                  key={index}
                  field={data.field}
                  header={data.header}
                  body={data.body}
                />
              ))}
            </DataTable>
          </TabPanel>
          <TabPanel header="Qualification">
            <DataTable
              emptyMessage="No record found."
              value={qualifications}
              globalFilter={globalFilter}
              className="custom-data-table"
            >
              {qualificationColumns.map((data, index) => (
                <Column
                  key={index}
                  field={data.field}
                  header={data.header}
                  body={
                    data.body
                      ? (rowData) =>
                          actionTemplate(rowData, openModal, "qualification")
                      : null
                  }
                />
              ))}
            </DataTable>
          </TabPanel>
          <TabPanel header="Countries">
            <DataTable
              emptyMessage="No record found."
              value={countries}
              globalFilter={globalFilter}
              className="custom-data-table"
            >
              {countriesColumns.map((data, index) => (
                <Column
                  key={index}
                  field={data.field}
                  header={data.header}
                  body={
                    data.body
                      ? (rowData) =>
                          actionTemplate(rowData, openModal, "countries")
                      : null
                  }
                />
              ))}
            </DataTable>
          </TabPanel>
          <TabPanel header="Services">
            <DataTable
              emptyMessage="No record found."
              value={services}
              className="custom-data-table"
            >
              {servicesColumns.map((data, index) => (
                <Column
                  key={index}
                  field={data.field}
                  header={data.header}
                  body={
                    data.body
                      ? (rowData) =>
                          actionTemplate(rowData, openModal, "services")
                      : null
                  }
                />
              ))}
            </DataTable>
          </TabPanel>
          <TabPanel header="Trainings">
            <DataTable
              emptyMessage="No record found."
              value={training}
              className="custom-data-table"
            >
              {trainingColumns.map((data, index) => (
                <Column
                  key={index}
                  field={data.field}
                  header={data.header}
                  body={
                    data.body
                      ? (rowData) =>
                          actionTemplate(rowData, openModal, "training")
                      : null
                  }
                />
              ))}
            </DataTable>
          </TabPanel>
        </TabView>
      </div>
    </>
  );
}
