import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import config from "../../../config";

const initialState = {
  user: null,
  loading: false,
  error: null,
};

export const loginUser = createAsyncThunk(
  "login/loginUser",
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.baseUrl}${config.authPath}`,
        body
      );
      return response.data;
    } catch (error) {
      const errorMessage =
        error.response && error.response.data.msg 
          ? error.response.data.msg 
          : error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
    logout(state) {
      localStorage.removeItem("user");
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        localStorage.setItem("user", JSON.stringify(action.payload));
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { logout, setUser } = loginSlice.actions;

export default loginSlice.reducer;
