import React from "react";
import { Button } from "primereact/button";
import editIcon from "../../../../../assets/assests/edit.png";
import eyeIcon from "../../../../../assets/assests/eye.png";

// Action template function
export const actionTemplate = (rowData, openModal, type) => {
  return (
    <div className="action-buttons flex">
      <Button
        className="p-button-rounded p-button-edit p-0 mr-3 action-images"
        aria-label="view"
        onClick={() => openModal('Details', rowData, type)}
      >
        <img src={eyeIcon} alt="Eye" />
      </Button>
      <Button
        className="p-button-edit p-0 mr-3"
        aria-label="Edit"
        onClick={() => openModal('Edit', rowData, type)}
      >
        <img src={editIcon} alt="Edit" />
      </Button>
    </div>
  );
};

export const employeeColumns = [
  { field: "empId", header: "Emp ID" },
  { field: "name", header: "Name" },
  { field: "cnic", header: "CNIC" },
  { field: "passport", header: "Passport" },
  { field: "ntn", header: "NTN" },
  { field: "religion", header: "Religion" },
  { field: "role", header: "Role/Post Held" },
];

export const qualificationColumns = [
  { field: "empId", header: "Emp Id" },
  { field: "employeeName", header: "Employee Name" },
  { field: "cnic", header: "CNIC" },
  { field: "academic", header: "Academic" },
  { field: "specialization", header: "Specialization" },
  { field: "university", header: "University" },
  { field: "country", header: "Country" },
  {
    header: "Action",
    body: actionTemplate,
  },
];


export const countriesColumns = [
  { field: "", header: "Emp Id" },
  { field: "", header: "Employee Name" },
  { field: "", header: "Country" },
  { field: "", header: "Purpose" },
  { field: "", header: "Duration from​" },
  { field: "", header: "Duration to​" },
  {
    header: "Action",
    body: actionTemplate,
  },
];



export const servicesColumns = [
  { field: "", header: "Emp Id" },
  { field: "", header: "Employee Name" },
  { field: "", header: "Service year" },
  { field: "", header: "Post Held & Grade" },
  { field: "", header: "Department​" },
  { field: "", header: "Station" },
  { field: "", header: "Post Held From​" },
  { field: "", header: "Post Held To" },
  {
    header: "Action",
    body: actionTemplate,
  },
];

export const trainingColumns = [
  { field: "", header: "Emp Id" },
  { field: "", header: "Employee Name" },
  { field: "", header: "Training Type" },
  { field: "", header: "Institution" },
  { field: "", header: "Duration from​" },
  { field: "", header: "Duration To​" },
  { field: "", header: "Country​" },
  {
    header: "Action",
    body: actionTemplate,
  },
];
