import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

const LeaveData = () => {
  const navigate = useNavigate();

  const leaves = [
    {
      type: "Study Leave",
      totalAvailable: 18,
      totalAvailed: 10,
      remaining: 10,
      leaveLimit: 28,
      updateType: "Carry Forward",
    },
    {
      type: "Maternity",
      totalAvailable: 24,
      totalAvailed: 12,
      remaining: 12,
      leaveLimit: 24,
      updateType: "Job Lifetime Limit",
    },
    {
      type: "Ex-Pakistan Leave",
      totalAvailable: 50,
      totalAvailed: 10,
      remaining: 40,
      leaveLimit: 50,
      updateType: "Carry Forward",
    },
    {
      type: "Medical Leave",
      totalAvailable: 15,
      totalAvailed: 5,
      remaining: 5,
      leaveLimit: 15,
      updateType: "Per Year Update",
    },
    {
      type: "Earned Leave",
      totalAvailable: 20,
      totalAvailed: 10,
      remaining: 10,
      leaveLimit: 20,
      updateType: "Per Year Update",
    },
    {
      type: "Casual Leave",
      totalAvailable: 12,
      totalAvailed: 6,
      remaining: 6,
      leaveLimit: 12,
      updateType: "Per Year Update",
    },
  ];

  const handleBackClick = () => {
    navigate("/leave");
  };

  return (
    <>
      <div className="grid align-items-center mb-3">
        <div className="col-12 md:col-6">
          <h5 className="pages-internal-heading">
            <span onClick={handleBackClick} style={{ cursor: "pointer" }}>
              Leave Management
            </span>{" "}
            &gt; Leave Details
          </h5>
        </div>
      </div>

      <div className="leave-grid">
        {leaves.map((leave, index) => (
          <div key={index} className="leave-card">
            <div className="leave-header">{leave.type}</div>
            <div className="leave-body">
              <div className="leave-row">
                <span>Total Available</span>
                <span className="leave-value">{leave.totalAvailable}</span>
              </div>
              <div className="leave-row">
                <span>Total Availed</span>
                <span className="leave-value">{leave.totalAvailed}</span>
              </div>
              <div className="leave-row">
                <span>Remaining</span>
                <span className="leave-value">{leave.remaining}</span>
              </div>
              <div className="leave-row">
                <span>Leave Limit to Apply</span>
                <span className="leave-value">{leave.leaveLimit}</span>
              </div>
              <div className="leave-row">
                <span>Leave Update Type</span>
                <span className="leave-value">{leave.updateType}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default LeaveData;
