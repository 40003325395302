import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { InputText } from "primereact/inputtext";
import { toast } from "react-toastify";
import config from "../../../../config";
import { useNavigate } from "react-router-dom";
export default function OtpScreen({ userName, onHide }) {
  const [otp, setOtp] = useState(["", "", "", ""]); 
  const inputRefs = useRef([]); 
  const navigate = useNavigate();
  useEffect(() => {
    if (otp.every((digit) => digit !== "")) {
      handleOtpSubmit();
    }
  }, [otp]);

  const handleOtpSubmit = async () => {
    const finalOtp = otp.join("");
    try {
      const response = await axios.post(
        `${config.baseUrl}api/Account/verify-otp`,
        {
          userName,
          otp: finalOtp,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data?.status === 200) {
        const token = response.data?.data?.accessToken; 
        if (token) {
          localStorage.setItem("authToken", token); 
        }
        toast.success("OTP verified successfully!");
        onHide(); 
        navigate("/"); 
      } else {
        toast.error(response.data.message || "OTP verification failed");
      } 
    } catch (error) {
      console.error("OTP verification error:", error);
      toast.error("OTP verification failed. Please try again.");
    }
  };

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value !== "" && index < 3) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "" && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  return (
    <>
    <div class="text-center" style={{  justifyContent: "center", gap: "10px" }} >
    <h3>Authentication</h3>
    <p>Please enter 4 - digit OTP send on your Registered mobile number </p>
</div>
    <div style={{ display: "flex", justifyContent: "center", gap: "10px",marginTop:'40px' }}>
   
      {otp.map((digit, index) => (
        <InputText
          key={index}
          value={digit}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          maxLength={1} 
          style={{ width: "50px", textAlign: "center" }}
          ref={(el) => (inputRefs.current[index] = el)} 
        />
      ))}
    </div>
    </>
  );
}
