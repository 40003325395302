import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import config from "../../../config";

const initialState = {
  regiterUser: null,
  loading: false,
  error: null,
};

export const registerUser = createAsyncThunk(
  "register/registerUser",
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.baseUrl}${config.registerUserPath}`,
        body
      );
      return response.data;
    } catch (error) {
      const errorMessage =
        error.response && error.response.data.msg 
          ? error.response.data.msg
          : error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    setRegisterUser(state, action) {
      state.regiterUser = action.payload;
    },
    logout(state) {
      localStorage.removeItem("regiterUser");
      state.regiterUser = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.loading = false;
        state.regiterUser = action.payload;
        localStorage.setItem("regiterUser", JSON.stringify(action.payload));
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { logout, setRegisterUser } = registerSlice.actions;

export default registerSlice.reducer;
