import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import ActionButtons from "../../../components/action_buttons";

const QualificationForm = ({ onHide, formData, onSave, isEdit, initialData }) => {
  
  const handleSave = () => {
    onSave();
  };

  return (
    <div className="main-form">
      <form>
        <div className="p-fluid formgrid grid">
          <div className="field md:col-4">
            <label htmlFor="name">Name</label>
            <InputText id="name" placeholder="Enter" />
          </div>

          <div className="field md:col-4">
            <label htmlFor="cnic">CNIC</label>
            <InputText id="cnic" placeholder="Enter" />
          </div>

          <div className="field md:col-4">
            <label htmlFor="academic">Academic</label>
            <InputText id="academic" placeholder="Enter" />
          </div>

          <div className="field md:col-4">
            <label htmlFor="specialization">Specialization</label>
            <InputText id="specialization" placeholder="Enter" />
          </div>

          <div className="field md:col-4">
            <label htmlFor="country">Country</label>
            <InputText id="country" placeholder="Enter" />
          </div>

          <div className="field md:col-4">
            <label htmlFor="university">University</label>
            <InputText id="university" placeholder="Enter" />
          </div>

          <div className="field md:col-4">
            <label htmlFor="startedfrom">Started From ​</label>
            <Calendar id="startedfrom" placeholder="Enter" showIcon />
          </div>
          <div className="field md:col-4">
            <label htmlFor="startedTo">Started to</label>
            <Calendar id="startedTo" placeholder="Enter" showIcon />
          </div>
        </div>

        <div className="p mt-4 form-buttons">
          <ActionButtons
            onCancel={onHide}
            onSave={handleSave}
            saveLabel="Save Changes"
            showSave={true}
            cancelLabel="Cancel"
          />
        </div>
      </form>
    </div>
  );
};

export default QualificationForm;
