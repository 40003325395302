import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import config from "../../../config";

export const getTokenFromState = (state) => state.loginUser.user?.token;

export const getAxiosInstanceWithToken = (token) => {
  return Axios.create({
    baseURL: config.baseUrl,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const dashboardReducer = createSlice({
  name: "dashboard",
  initialState: {
    dashboardList: [],
    bookedSlotsThisYearCount: 0,
    bookedSportsComplexCount: 0,
    bookedSlotsAmountSum: 0,
    sportsComplexesCount: 0,
    usersCount: 0,
    usersCountThisYear: 0,
    loading: false,
    loadingBookedSlotsThisYearCount: false,
    loadingBookedSportsComplexCount: false,
    loadingBookedSlotsAmountSum: false,
    loadingUsersCount: false,
    loadingUsersCountThisYear: false,
    error: null,
  },
  reducers: {
    resetChangeStatus(state, action) {
      state.addSuccess = undefined;
      state.updateSuccess = undefined;
      state.addSuccess = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBookedSlotsThisYearCount.pending, (state) => {
        state.loadingBookedSlotsThisYearCount = true;
        state.error = null;
      })
      .addCase(getBookedSlotsThisYearCount.fulfilled, (state, action) => {
        state.loadingBookedSlotsThisYearCount = false;
        state.error = null;
        state.bookedSlotsThisYearCount = action.payload;
      })
      .addCase(getBookedSlotsThisYearCount.rejected, (state, action) => {
        state.loadingBookedSlotsThisYearCount = false;
        state.error = action.payload;
      })
      .addCase(getBookedSportsComplexCount.pending, (state) => {
        state.loadingBookedSportsComplexCount = true;
        state.error = null;
      })
      .addCase(getBookedSportsComplexCount.fulfilled, (state, action) => {
        state.loadingBookedSportsComplexCount = false;
        state.error = null;
        state.bookedSportsComplexCount = action.payload;
      })
      .addCase(getBookedSportsComplexCount.rejected, (state, action) => {
        state.loadingBookedSportsComplexCount = false;
        state.error = action.payload;
      })
      .addCase(getBookedSlotsAmountSum.pending, (state) => {
        state.loadingBookedSlotsAmountSum = true;
        state.error = null;
      })
      .addCase(getBookedSlotsAmountSum.fulfilled, (state, action) => {
        state.loadingBookedSlotsAmountSum = false;
        state.error = null;
        state.bookedSlotsAmountSum = action.payload;
      })
      .addCase(getBookedSlotsAmountSum.rejected, (state, action) => {
        state.loadingBookedSlotsAmountSum = false;
        state.error = action.payload;
      })
      .addCase(getSportsComplexesCount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSportsComplexesCount.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.sportsComplexesCount = action.payload;
      })
      .addCase(getSportsComplexesCount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getUsersCount.pending, (state) => {
        state.loadingUsersCount = true;
        state.error = null;
      })
      .addCase(getUsersCount.fulfilled, (state, action) => {
        state.loadingUsersCount = false;
        state.error = null;
        state.usersCount = action.payload;
      })
      .addCase(getUsersCount.rejected, (state, action) => {
        state.loadingUsersCount = false;
        state.error = action.payload;
      })
      .addCase(getUsersCountThisYear.pending, (state) => {
        state.loadingUsersCountThisYear = true;
        state.error = null;
      })
      .addCase(getUsersCountThisYear.fulfilled, (state, action) => {
        state.loadingUsersCountThisYear = false;
        state.error = null;
        state.usersCountThisYear = action.payload;
      })
      .addCase(getUsersCountThisYear.rejected, (state, action) => {
        state.loadingUsersCountThisYear = false;
        state.error = action.payload;
      });
  },
});

export default dashboardReducer.reducer;
export const { resetChangeStatus } = dashboardReducer.actions;

export const getBookedSlotsThisYearCount = createAsyncThunk(
  "bookedSlotsThisYearCount/fetch",
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const token = getTokenFromState(state);
      const axiosInstance = getAxiosInstanceWithToken(token);

      const response = await axiosInstance.get(
        `${config.dashboardPath}/BookedSlotsThisYearCount`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);

export const getBookedSportsComplexCount = createAsyncThunk(
  "bookedSportsComplexCount/fetch",
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const token = getTokenFromState(state);
      const axiosInstance = getAxiosInstanceWithToken(token);

      const response = await axiosInstance.get(
        `${config.dashboardPath}/BookedSportsComplexCount`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);

export const getBookedSlotsAmountSum = createAsyncThunk(
  "bookedSlotsAmountSum/fetch",
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const token = getTokenFromState(state);
      const axiosInstance = getAxiosInstanceWithToken(token);

      const response = await axiosInstance.get(
        `${config.dashboardPath}/BookedSlotsAmountSum`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);

export const getSportsComplexesCount = createAsyncThunk(
  "sportsComplexesCount/fetch",
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const token = getTokenFromState(state);
      const axiosInstance = getAxiosInstanceWithToken(token);

      const response = await axiosInstance.get(
        `${config.dashboardPath}/SportsComplexesCount`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);

export const getUsersCount = createAsyncThunk(
  "usersCount/fetch",
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const token = getTokenFromState(state);
      const axiosInstance = getAxiosInstanceWithToken(token);

      const response = await axiosInstance.get(
        `${config.dashboardPath}/UsersCount`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);

export const getUsersCountThisYear = createAsyncThunk(
  "usersCountThisYear/fetch",
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const token = getTokenFromState(state);
      const axiosInstance = getAxiosInstanceWithToken(token);

      const response = await axiosInstance.get(
        `${config.dashboardPath}/UsersCountThisYear`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);
