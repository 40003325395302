import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import ActionButtons from "../../components/action_buttons";

const EditAddForm = ({ onHide, formData }) => {
  const genderOptions = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ];

  const languageOptions = [
    { label: "English", value: "English" },
    { label: "Urdu", value: "Urdu" },
  ];

  const religionOptions = [
    { label: "Muslim", value: "Muslim" },
    { label: "Non-Muslim", value: "Non-Muslim" },
  ];

  const maritalStatusOptions = [
    { label: "Married", value: "Married" },
    { label: "Single", value: "Single" },
  ];

  const [fileName, setFileName] = useState("");

  const handleFileChange = (e) => {
    setFileName(e.target.files[0]?.name || "");
  };

  return (
    <div className="main-form">
      <form>
        <div className="p-fluid formgrid grid">
          <div className="field md:col-3">
            <label htmlFor="name">Name</label>
            <InputText id="name" placeholder="Enter" />
          </div>
          <div className="field md:col-3">
            <label htmlFor="parentage">S/O, D/O, H/O, W/O</label>
            <InputText id="parentage" placeholder="Enter" />
          </div>
          <div className="field md:col-3">
            <label htmlFor="cnic">CNIC</label>
            <InputText id="cnic" placeholder="Enter" />
          </div>
          <div className="field md:col-3">
            <label htmlFor="placeOfBirth">Place of Birth</label>
            <InputText id="placeOfBirth" placeholder="Enter" />
          </div>
          <div className="field md:col-3">
            <label htmlFor="passportNo">Passport No</label>
            <InputText id="passportNo" placeholder="Enter" />
          </div>
          <div className="field md:col-3">
            <label htmlFor="ntnNo">NTN No</label>
            <InputText id="ntnNo" placeholder="Enter" />
          </div>
          <div className="field md:col-3">
            <label htmlFor="gender">Gender</label>
            <Dropdown
              id="gender"
              options={genderOptions}
              placeholder="Select Gender"
            />
          </div>
          <div className="field md:col-3">
            <label htmlFor="language">Language</label>
            <Dropdown
              id="language"
              options={languageOptions}
              placeholder="Select Language"
            />
          </div>
          <div className="field md:col-3">
            <label htmlFor="cadre">Cadre / Ex Cadre</label>
            <InputText id="cadre" placeholder="Enter" />
          </div>
          <div className="field md:col-3">
            <label htmlFor="occupationalGroupService">
              Occupational Group Service
            </label>
            <InputText id="occupationalGroupService" placeholder="Enter" />
          </div>
          <div className="field md:col-3">
            <label htmlFor="seniorityPosition">Seniority Position</label>
            <InputText id="seniorityPosition" placeholder="Enter" />
          </div>
          <div className="field md:col-3">
            <label htmlFor="dateOfJoiningPrevious">
              Date of Joining (previous)
            </label>
            <Calendar id="dateOfJoiningPrevious" placeholder="Enter" showIcon />
          </div>
          <div className="field md:col-3">
            <label htmlFor="dateOfJoiningCurrent">
              Date of Joining (current)
            </label>
            <Calendar id="dateOfJoiningCurrent" placeholder="Enter" showIcon />
          </div>
          <div className="field md:col-3">
            <label htmlFor="dateOfBirth">Date of Birth</label>
            <Calendar id="dateOfBirth" placeholder="Enter" showIcon />
          </div>
          <div className="field md:col-3">
            <label htmlFor="religion">Religion</label>
            <Dropdown
              id="religion"
              options={religionOptions}
              placeholder="Select Religion"
            />
          </div>
          <div className="field md:col-3">
            <label htmlFor="appointmentWithStatus">
              Appointment with Status​​
            </label>
            <InputText id="appointmentWithStatus" placeholder="Enter" />
          </div>
          <div className="field md:col-3">
            <label htmlFor="maritalStatus">Marital Status</label>
            <Dropdown
              id="maritalStatus"
              options={maritalStatusOptions}
              placeholder="Select Marital Status"
            />
          </div>
          <div className="field md:col-3">
            <label htmlFor="dateOfSuperannuation">Date of Superannuation</label>
            <Calendar id="dateOfSuperannuation" placeholder="Enter" showIcon />
          </div>
          <div className="field md:col-3">
            <label htmlFor="domicile">Domicile</label>
            <InputText id="domicile" placeholder="Enter" />
          </div>
          <div className="field md:col-3">
            <label htmlFor="homeDistrict">Home District</label>
            <InputText id="homeDistrict" placeholder="Enter" />
          </div>
          <div className="field md:col-3">
            <label htmlFor="currentPost">Current Post</label>
            <InputText id="currentPost" placeholder="Enter" />
          </div>
          <div className="field md:col-3">
            <label htmlFor="currentPostCity">Current Post City</label>
            <InputText id="currentPostCity" placeholder="Enter" />
          </div>
          <div className="field md:col-3">
            <label htmlFor="employeeBatch">Employee Batch</label>
            <InputText id="employeeBatch" placeholder="Enter" />
          </div>
          <div className="field md:col-3">
            <label htmlFor="currentlyWorkingIn">Currently Working In</label>
            <InputText id="currentlyWorkingIn" placeholder="Enter" />
          </div>
          <div className="field md:col-3">
            <label>Attach Picture *</label>
            <div className="file-container">
              <input
                type="file"
                onChange={handleFileChange}
                id="attach-picture"
                className="none-input"
              />
              <label htmlFor="attach-picture" className="attach-picture">
                Choose file
              </label>
              <span className="file-name">{fileName || "No file chosen"}</span>
            </div>{" "}
          </div>
          <div className="field md:col-3">
            <label htmlFor="employeeSignature">Employee Signature</label>
            <div className="file-container">
              <input
                type="file"
                onChange={handleFileChange}
                className="none-input"
                id="employee-signature"
              />
              <label htmlFor="employee-signature" className="attach-picture">
                Choose file
              </label>
              <span className="file-name">{fileName || "No file chosen"}</span>
            </div>
          </div>
        </div>
        <div className="p mt-4 form-buttons">
          <ActionButtons
            onCancel={onHide}
            onSave={onHide}
            saveLabel="Save Changes"
            showSave={true}
            cancelLabel="Cancel"
          />{" "}
        </div>
      </form>
    </div>
  );
};

export default EditAddForm;
