import React, { useState, useEffect } from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import LineChart from "./line-chart";
import BarChart from "./bar-chart";
import sun from "../../../../assets/assests/sun.png";
import employee from "../../../../assets/assests/Employee.png";
import early from "../../../../assets/assests/Early Arrival.png";
import time from "../../../../assets/assests/Time-off.png";
import present from "../../../../assets/assests/Present.png";
import absent from "../../../../assets/assests/Absent.png";
import late from "../../../../assets/assests/Late Arrival.png";
import add from "../../../../assets/assests/Add-grapgh.png";
import up from "../../../../assets/assests/Graph Up.png";
import down from "../../../../assets/assests/Graph down.png";
import down2 from "../../../../assets/assests/down.png";

import moment from "moment";

export default function DashboardScreen() {
  const navigate = useNavigate();

  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const formatTime = (time) => {
    return moment(time).format("hh:mm:ss A");
  };
  const date = moment().format("DD MMMM YYYY");

  const statsData = [
    {
      title: "452",
      description: "Total Employees",
      smallText: "2 new employees added!",
      image: employee,
      icon: add,
    },
    {
      title: "360",
      description: "Present",
      smallText: "-10% Less than yesterday",
      image: present,
      icon: up,
    },
    {
      title: "30",
      description: "Absent",
      smallText: "+3% Increase than yesterday",
      image: absent,
      icon: down,
    },
    {
      title: "62",
      description: "Late Arrival",
      smallText: "+3% Increase than yesterday",
      image: late,
      icon: down,
    },
    {
      title: "6",
      description: "Early Arrival",
      smallText: "-10% Less than yesterday",
      image: early,
      icon: up,
    },
    {
      title: "42",
      description: "Time-off",
      smallText: "2% Increase than yesterday",
      image: time,
      icon: down2,
    },
  ];

  const handleViewAttendanceClick = () => {
    navigate("/attendance");
  };
  return (
    <>
      <div className="dashboard">
        <div className="dashboard-screen">
          {/* Time Section */}
          <div className="time-section">
            <div className="time-card">
              <div className="time-header">
                <img src={sun} />
                <div className="content">
                  <h4>{formatTime(currentTime)}</h4>
                  <span>Realtime Insight</span>
                </div>
              </div>
              <div className="time-body">
                <p>
                  Today: <br />
                  <span>{date}</span>
                </p>
              </div>
              <Button
                label="View Attendance"
                className="attendance-button p-button-success"
                onClick={handleViewAttendanceClick}
              />
            </div>
          </div>

          <div className="stats-section">
            <div className="grid">
              {statsData.map((stat, index) => (
                <div key={index} className="md:col-12 lg:col-4 mobile-graphs">
                  <Card className="stat-card" title={stat.title}>
                    <p>{stat.description}</p>
                    <small>
                      <img src={stat.icon} />
                      {stat.smallText}
                    </small>
                    <img
                      src={stat.image}
                      className={`${stat.icon} stat-icon`}
                    />
                  </Card>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="grid">
          <div className="md:col-12 lg:col-6 mobile-graphs">
            <div className="card charts-card">
              <h6>Attendance Status</h6>
              <LineChart />
            </div>
          </div>
          <div className="md:col-12 lg:col-6 mobile-graphs">
            <div className="card charts-card">
              <h6>Weekly Attendance </h6>
              <BarChart />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
