import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import { useNavigate } from "react-router-dom";
import editIcon from "../../../../assets/assests/edit.png";
import eyeIcon from "../../../../assets/assests/eye.png";
import { Dialog } from "primereact/dialog";
import EditAddForm from "./edit-add-form";
import config from "../../../../config";
export default function EmployeeScreen() {
  const navigate = useNavigate();

  const [globalFilter, setGlobalFilter] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [employeeList, setEmployeeList] = useState();


  const GetEmployee=async()=>{
    const token =localStorage.getItem('authToken');
    try{
      const response= await fetch(`${config.baseUrl}api/Employee/GetAllEmployees`,
        {
          method:'Get',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      )
      const data =await response.json();
      setEmployeeList(data)

    }
    catch(error){

    }

  };
  useEffect(()=>{
    GetEmployee()
  },[])
  const handleGlobalFilterChange = (e) => {
    setGlobalFilter(e.target.value);
  };

  const onHide = () => {
    setShowDialog(false);
  };

  const employees = [
    {
      empId: "E001",
      name: "John Doe",
      cnic: "12345-6789012-3",
      passport: "P1234567",
      ntn: "NTN123456",
      religion: "Islam",
      role: "Manager",
    },
    {
      empId: "E002",
      name: "Jane Smith",
      cnic: "23456-7890123-4",
      passport: "P2345678",
      ntn: "NTN234567",
      religion: "Christianity",
      role: "Developer",
    },
    {
      empId: "E003",
      name: "Michael Brown",
      cnic: "34567-8901234-5",
      passport: "P3456789",
      ntn: "NTN345678",
      religion: "Hinduism",
      role: "Analyst",
    },
  ];

  const toggleDialogMode = (editMode = false) => {
    setShowDialog(true);
    setIsEdit(editMode);
  };

  const actionTemplate = (rowData) => {
    return (
      <div className="action-buttons flex">
        <Button
          className="p-button-rounded p-button-edit p-0 mr-3 action-images"
          onClick={() => {
            navigate(`/employee/details/${rowData.empId}`, { state: { employee: rowData } });
          }}
          aria-label="view"
        >
          <img src={eyeIcon} alt="Eye" />
        </Button>
        <Button
          className=" p-button-edit p-0 mr-3"
          onClick={() => {
            toggleDialogMode(true);
            setSelectedData(rowData);
          }}
          aria-label="Edit"
        >
          <img src={editIcon} alt="Edit" />
        </Button>
      </div>
    );
  };

  const columnsData = [
    {
      field: "id",
      header: "Emp ID",
    },
    {
      field: "employeeName",
      header: "Name",
    },
    {
      field: "cnic",
      header: "Cnic",
    },
    {
      field: "passportNumber",
      header: "Passport",
    },
    {
      field: "nationalTaxNumber",
      header: "NTN",
    },
    {
      field: "religion",
      header: "Religion",
    },
    {
      field: "cadre",
      header: "Role/Post Held",
    },
    {
      header: "Action",
      body: actionTemplate,
    },
  ];

  const handleDetailsClick = (rowData) => {
    navigate(`/employee/details/${rowData.empId}`, { state: { employee: rowData } });
  };
  const rowClassName = "p-datatable-clickable";


  return (
    <>
      <Dialog
        header={
          <div className="popup-header">
            <div className="popup-header-text">
              {isEdit ? "Edit Employee" : "Add New Employee"}
            </div>
          </div>
        }
        visible={showDialog}
        onHide={onHide}
        className="dialog-size"
      >
        <EditAddForm onHide={onHide} formData={isEdit ? selectedData : null} />
      </Dialog>
      <div className="grid align-items-center mb-3">
        <div className="col-12 md:col-6">
          <h5 className="pages-internal-heading">Employee Management</h5>
        </div>

        <div className="col-12 md:col-6 justify-content-end filter-responsive">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="mbl_view"
              placeholder="Search"
              value={globalFilter}
              onChange={handleGlobalFilterChange}
            />
          </span>
          <Button
            label="Add New"
            icon="pi pi-plus"
            onClick={() => toggleDialogMode(false)}
            className="p-button ml-3"
          />
        </div>
      </div>

      <div className="card">
        <DataTable
          emptyMessage="No record found."
          value={employeeList}
          paginator
          rows={10}
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          className="custom-data-table"
          globalFilter={globalFilter}
          onRowClick={(event) => handleDetailsClick(event.data)}
          rowClassName={rowClassName}
        >
          {columnsData.map((data, index) => (
            <Column
              key={index}
              field={data.field}
              header={data.header}
              body={data.body}
            />
          ))}
        </DataTable>
      </div>
    </>
  );
}
