import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import config from "../../../config";
import { toast } from "react-toastify";

// Initial state
const initialState = {
  usersList: [],
  totalRecords: 0,
  rowData: null,
  loading: false,
  error: null,
};

// Thunk for fetching user complexes
export const getUsersList = createAsyncThunk(
  "user/fetch",
  async (
    { isActive, pageNo, pageSize, searchText, token },
    { rejectWithValue }
  ) => {
    try {
      const formData = new FormData();
      formData.append("isActive", isActive);
      formData.append("PageNo", pageNo);
      formData.append("PageSize", pageSize);
      formData.append("SearchText", String(searchText));

      const response = await Axios.post(
        `${config.baseUrl}${config.usersPath}/get`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (
        response.data &&
        response.data.data &&
        Array.isArray(response.data.data)
      ) {
        return {
          usersList: response.data.data,
          totalRecords: response.data.recordsTotal,
        };
      } else {
        throw new Error("Invalid response format");
      }
    } catch (error) {
      console.error("Error:", error);
      return rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);

// Thunk for creating a user
export const createUser = createAsyncThunk(
  "user/create",
  async (
    { mobileNumber, name, email, pin, role, token, profileImageFile },
    { rejectWithValue }
  ) => {
    try {
      const formData = new FormData();
      formData.append("mobileNumber", mobileNumber);
      formData.append("name", name);
      formData.append("email", email);
      formData.append("pin", pin);
      formData.append("role", role);
      formData.append("profileImageFile", profileImageFile);

      const response = await Axios.post(
        `${config.baseUrl}/api/Users`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data) {
        return response.data; 
      } else {
        throw new Error("Invalid response format");
      }
    } catch (error) {
      console.error("Error creating user:", error);
      return rejectWithValue(error.response); 
    }
  }
);

// Thunk to delete user 
export const deleteUser = createAsyncThunk(
  "user/delete",
  async ({ id, token }, { rejectWithValue }) => {
    try {
      await Axios.delete(
        `${config.baseUrl}/api/Users/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return id; // Return the deleted user ID
    } catch (error) {
      throw rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);

// Thunk for updating a user
export const updateUser = createAsyncThunk(
  "user/update",
  async (
    { id, token, ...data },
    { rejectWithValue }
  ) => {
    try {
      const formData = new FormData();
      formData.append("Id", id);
      Object.keys(data).forEach(key => {
        if (data[key] !== undefined && data[key] !== null) {
          formData.append(key, data[key]);
        }
      });

      const response = await Axios.put(
        `${config.baseUrl}/api/Users`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data) {
        return response.data; 
      } else {
        throw new Error("Invalid response format");
      }
    } catch (error) {
      console.error("Error updating user:", error);
      return rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);


// Slice definition
const userReducer = createSlice({
  name: "users",
  initialState,
  reducers: {
    resetState(state) {
      state.addSuccess = undefined;
      state.updateSuccess = undefined;
      state.deleteSuccess = undefined;
    },
    setRowData(state, action) {
      state.rowData = action.payload;
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsersList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUsersList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.usersList = action.payload.usersList;
        state.totalRecords = action.payload.totalRecords;
      })
      .addCase(getUsersList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createUser.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        toast.error(action.payload.data); 
      })
      .addCase(deleteUser.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.deleteSuccess = undefined;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.deleteSuccess = true;
        state.usersList = state.usersList.filter(user => user.id !== action.payload); // Assuming user object has an 'id' field

        toast.success("User Inactive successfully");
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.deleteSuccess = false;
        toast.error("Failed to inactive user");
      })
      .addCase(updateUser.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.updateSuccess = undefined;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.updateSuccess = true;
        toast.success("User updated successfully");
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.updateSuccess = false;
        toast.error("Failed to update user");
      });
  },
});

// Export reducer and actions
export default userReducer.reducer;
export const { resetState, setRowData } = userReducer.actions;
